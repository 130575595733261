 .modal-dialog.modal-lg {
   max-width: 90% !important;
 }

 .formLabel {
   border: 1px solid #dee2e6;
   padding: 0.7rem;
   background: #f8f9fa;
   color: #495057;
   border-top-right-radius: 3px;
   border-top-left-radius: 3px;
 }

 .formContent {
   border: 1px solid #dee2e6;
   padding: 0.7rem 0.7rem 0.2rem 0.5rem;
   background: #fff;
   color: #495057;
   border-bottom-right-radius: 3px;
   border-bottom-left-radius: 3px;
 }

 .formContent1 {
   border: 1px solid #dee2e6;
   padding: 0.7rem 1rem 0.2rem .4rem;
   background: #fff;
   color: #495057;
   border-bottom-right-radius: 3px;
   border-bottom-left-radius: 3px;
 }

 .icon {
   color: #fff;
   font-size: 12px;
 }

 .MuiToolbar-root {
   justify-content: center !important;
 }

 .MuiTablePagination-spacer {
   flex: 0 !important;
 }

 .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
   flex-direction: row-reverse;
 }

 button.btn-close {
   font-size: 10px;
 }

 textarea#formBasicCheckbox {
   font-size: 0.75rem;
 }

 .form-select {
   font-size: 15px !important;
 }

 input[type=checkbox] {
   width: 20px;
   height: 20px;
   margin-top: 0rem;
   border: 2px solid #ced4da;
   transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;

 }

 input[type=radio] {
   width: 20px;
   height: 20px;
   margin-top: 0rem;
   border: 2px solid #ced4da;
   transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;

 }

 .form-check-input:hover {
   border-color: #316a9e !important;
   color: #fff;
 }

 .form-check-input:focus {
   border: 2px solid #ced4da !important;
   box-shadow: 0 0 0 0.2rem #a6d5fa !important;
 }

 .form-check-input:checked {
   border-color: #316a9e !important;
   background-color: #316a9e !important;
 }

 .form-check-input:checked[type=radio] {
   background-color: #fff !important;
   border: 4px solid #316a9e !important;
 }

 .form-check-input:checked[type=checkbox] {
   background-color: #316a9e !important;
   border: 4px solid #316a9e !important;
 }

 .modal-header {
   border: none !important;
 }

 .calenderClass1 {
   color: black;
   background-color: #fff;
   width: 100%;
   height: 35px;
   border-radius: 3px;
 }

 label.form-check-label {
   padding-top: 5px;
 }

 .LightText {
   font-weight: 400;
 }

 .p-fluid .p-calendar {
   display: flex;
 }

 .p-datepicker {
   min-width: auto !important;
 }

 @media only screen and (min-width: 320px) and (orientation: portrait) {
   .modal-dialog.modal-lg {
     max-width: 100% !important;
   }
   .p-dropdown-panel {
     max-width: 78vw!important;
   } 
   .p-dropdown-panel ul li{
    text-overflow: ellipsis;
   }
  }

 .review-space {
   margin-top: 10px;
 }

 .scoring {
   margin-top: -7px;
 }

 .removehscroll {
   overflow-x: hidden !important;
 }
 @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .modal-dialog.modal-lg {
    max-width: 100% !important;
  }
  .p-dropdown-panel {
    max-width: 78vw!important;
  } 
  .p-dropdown-panel ul li{
   text-overflow: ellipsis;
  }
 }

 .removepadding{
  padding-left: 0px!important;
 }