
.bodyClass {
  /* Image Location */
  background-image: url(../../contents/images/BG-iPad-potrait-1x.jpg);
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
}
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait){
.bodyClass {
  /* Image Location */
  background-image: url(../../contents/images/BG-iPad-potrait-1x.jpg);
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
}
}

@media only screen and (min-width : 280px) and (max-width : 768px) and (orientation : portrait) {
  .bodyClass {
    /* Image Location */
    background-image: url(../../contents/images/BG-iPhone-potrait-1x.jpg);
    /* Background image is centered vertically and horizontally at all times */
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
  }
  }
  @media screen and (min-width : 1024px){
    .bodyClass {
      /* Image Location */
      background-image: url(../../contents/images/BG_web.jpg);
      /* Background image is centered vertically and horizontally at all times */
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: fixed;
    }
    }
    /* @media screen and (min-width : 320px) and (max-width : 768px){
      .main-container
      {
        padding-right: 11%;
      }
    } */
.center{
  text-align: center;
}
body .topheader {
  position: absolute;
  background-color: #004a87;
  width: calc(100% - 49.5px);
  height: 16px;
  top: 0;
  right: 0;
  float: right;
}

body .topheader-sliced {
  position: absolute;
  background-color: #004a87;
  width: 50px;
  height: 16px;
  top: 0;
  float: left;
  clip-path: polygon(100% 0%, 0% 0%, 100% 100%);
}

body .topheader-container {
  position: fixed;
  width: 40%;
  height: 16px;
  top: 0;
  right: 0;    
}
body .bottomfooter-container {
  width: 60%;
  height: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
}

body .bottomfooter {
  position: absolute;
  background-color: #004a87;
  width: calc(100% - 49.5px);
  height: 16px;
  bottom: 0;
  float: left;
}

body .bottomfooter-sliced {
  position: absolute;
  background-color: #004a87;
  width: 50px;
  height: 16px;
  bottom: 0;
  float: right;
  right: 0;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
.Login-container{
  background-color: 'White' !important;
  /* height: '100vh' !important;*/
}
.main-container{
  float: left !important;
  margin-top: 5%;
  /* margin-left: 42px; */
}
.Napa-Image{
  alt:"napa logo";
  margin-bottom: 42px;
  margin-top: 54px;
  vertical-align: middle;
  border: 0;
  /* Full height */
  width: 75%;
  height: 75%;
}
.Napa-text-00{
  font-weight: 600;
  font-size: 15px;
  color: #223441;
  margin-left: 5%;
    margin-right: 5%; 
}
.Napa-text-01{
  font-family: 'opensansbold', sans-serif;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  box-sizing: border-box;
  margin: 0 0 10px;
  text-align: center;
}
.Login-button{
  background-color: #1597d0;
    border-radius: 10px;
    width: 62%;
    height: 55px;
    margin-top: 30px !important;
    color: #FFF;
    position: relative;
    border: none;
    font-size: 16px;
    -webkit-appearance: button;
    cursor: pointer;
    line-height: inherit;
    margin: 0;
    font: inherit;
    user-select: none;
    white-space: pre;
    align-items: center !important;
    text-align: center;
    padding: 1px 6px;
    margin-bottom: 20px;
}
.Napa-text-passwordHelp{
  font-weight: 600;
    font-size: 13px;
    color: #5a656f;
}
.Napa-text-02{
  font-weight: 600;
  font-size: 11px;
  color: #949da6;
  text-align: center;
  box-sizing: border-box;
  display: block;
  padding-left: 10%;
  padding-right: 10%;
}
.container-1{
  height: fit-content;
  max-width: 600px !important;
  object-fit: cover;
  object-position: bottom;
}


html, body {
  height: 100%;
  width: 100%;
}
/* html {
  overflow: auto;
} */

@media only screen and (min-width: 320px) and (max-width: 768px) and (orientation: portrait){
  body .topheader-container, body .bottomfooter-container, body .topheader, body .bottomfooter {
    width: 100%;
    height: 15.2px;
}
.login-container-row {
  height: auto !important;
}
}
.toast-demo{
  min-width: 10rem;
  margin-right: .5rem;
}

@media screen and (max-width: 960px) {
  .toast-demo {
      width: 100%;
      margin-bottom: .5rem;
  }
}