.fileViewer .modal-dialog {
  height: 500px; /* Set your desired height */
  max-height: 80vh; /* Ensures responsiveness */
}

.fileViewer .modal-content {
  height: 100%;
}

/* Ensure modal is responsive */
@media (max-width: 576px) {
  .fileViewer .modal-dialog {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .fileViewer iframe {
    height: calc(100vh - 56px); /* Adjust iframe height minus modal header/footer if necessary */
  }
}