.calender-custom{
    height: 46%!important;
}
.p-button{
    background: #316a9e !important;
    border: 1px solid #316a9e !important;
    border-radius: 0px 3px 3px 0px !important;
}
.buttonsize span button{
    width: 30px!important;
}
.pi-info-circle{
    color: #1889f0; 
}
.pi-info-circle:hover{
    cursor: pointer;
}