.inspection-title {
    color: #495057;
    font-size: 15px;
    font-weight: 600;
}

.inspection-form {
    color: #495057;
    font-size: 12px;
    font-weight: 600;
    margin-left: -11px;
}

.inspection-form label.form-control-header {
    font-weight: bold;
    font-size: 12px;
}

.inspection-datetime {

    color: black;
    background-color: #fff;
    width: 100%;
    height: 35px;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.inspection-datetime .react-datetime-picker__wrapper {
    border: none !important;
}

.inspection-form .input-validation-error {
    border: 2px solid red !important;
    border-radius: .25rem !important;
}

.modal-dialog-scrollable .modal-content {
    border-radius: 8px;
    max-height: 100%;
    overflow: hidden;
}

.form-details {
    font-weight: lighter;
}

.date-time-picker{
    color: red; 
    background-color: red !important;
}

.modal.show .modal-dialog {
    transform: none;
    display: flex;
    align-items: center;
}

.calender-custom input[type="text"]{
    height: 30px;
    font-size: 12px;
}


label.form-check-label {
    padding-top: 0px !important;
}

.row-correction{
    margin-left: -5px; 
}

.comment-text{
    font-size: 12px;
}

.p-button{
    background-color: #316a9e;
    border-radius: 0px 3px 3px 0px;
    border-color:#316a9e;
}

/* .p-button :hover {
    background-color: #316a9e;
}
     */

.p-button.p-button-icon-only {
    width:30px;
    height: 30px;
    padding: 0.75rem 0;
}

.p-button.p-button-icon-only :hover {
    background-color: #316a9e !important;
}
.inspection-control
{
    margin-top: -35px;
}
.comment-control
{
    margin-top: -14px;
}
.primary-review-control{
    margin-top: -30px;
}
.completion-details
{
    margin-top: -5px;
}
@media screen and (max-width:769px) {
    .secondary-review{
      margin-top: -32px;
    }
    .comment-control{
        margin-top: -12px;
      }
  }