body.modal-open {
    /* overflow: visible !important; */
    padding-right: 0px !important;
}

.user-profile-popup {
    padding-right: 0px !important;
}

.custom-dialog {
    top: 30px;
    font-size: 12px;
    float: right;
}

.sign-out-align {
    text-align: right;
}

@media screen and (max-width: 770px) {
    .custom-dialog {
        width: 55% !important;
    }

    .custom-dialog .popup-user {
        margin-bottom: 10px !important;
    }

    .custom-dialog .popup-user-info {
        margin-left: -14px !important;
    }

    .sign-out-align {
        text-align: left;
        margin-left: -15px;
        margin-top: 7px !important;
    }
}

.user-profile-backdrop {
    display: none;
}

.sign-out {
    cursor: pointer;
    color: #0078d6;
}

.sign-out:hover {
    text-decoration: underline;
}

.custom-dialog .popup-user-icon {
    height: 60px;
    width: 60px;
    text-align: center;
    margin-left: 3px;
    color: #fff;
    background-color: #316a9e;
    border-radius: 50%;
}

.custom-dialog .popup-user-info {
    margin-left: 10px;
}

.custom-dialog .name {
    font-weight: bold;
}

.custom-dialog .btn {
    color: #fff;
    background: #316a9e;
    border: 1px solid #316a9e;
    padding: 5px 10px 5px 10px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
}

.custom-dialog .btn:hover {
    color: #fff;
}

.imageClass {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.toolTip
{
    max-width: 100%
}
@media screen and (max-width:769px) {
    img {display:block;margin:0 auto;}
}
.scrollBarTooltip::-webkit-scrollbar{
    width: 5px;
}
.scrollBarTooltip::-webkit-scrollbar-thumb{
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: black;
    border: 1px solid #000;
}
.scrollBarTooltip::-webkit-scrollbar-track{
    border: 1px solid #000;
    /* padding: 2px 0; */
    background-color: #495057;
}
