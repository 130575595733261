body{
  font-family: Roboto,Arial,Helvetica,sans-serif !important;
  background-color: #e8e8ea !important;
}

button.react-date-picker__calendar-button.react-date-picker__button {
  background-color: #316a9e;
  border-radius: 0px 3px 3px 0px;
}
button.react-date-picker__calendar-button.react-date-picker__button:hover{
  background-color: #669acd;
  border-color: #669acd;
  border-radius: 0px 3px 3px 0px;

}
.react-date-picker__wrapper{
  border: thin solid #ced4da;
  border-radius: 3px;
}
.react-date-picker__wrapper:hover{
  border: thin solid #316a9e;
  border-radius: 3px;
}
.react-date-picker__inputGroup{
  font-size: 12px;
  padding: 3px 2px 2px 5px;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  line-height: 1.125em;
  color: #495057 !important;
  font-family: Open Sans,Helvetica,Arial,sans-serif;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  color: #495057 !important;
  text-decoration: none !important;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  width: 2.5rem;
  height: 2.9rem;
  transition: box-shadow .2s;
  border: 1px solid #0000;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #dee2e6;

}
.react-calendar__navigation button {
  color: #495057;
  font-weight: 600;
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.5em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ced4da;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ced4da;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 12px;
  font-weight: 600;
}
.react-date-picker__calendar.react-date-picker__calendar--open {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px 0 #00000024, 0 1px 10px 0 #0000001f;
}
.react-calendar__navigation__prev2-button{
  display: none;
}
.react-calendar__navigation__next2-button{
  display: none;
}
.react-calendar__navigation__prev-button{
  font-size: 30px;
  font-weight: 100;
}
.react-calendar__navigation__next-button{
  font-size: 30px;
  font-weight: 100;
}
.react-date-picker__inputGroup__input:invalid {
  background: #fff!important;
  font-weight: 400;
}

/* #loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: -40px 0 0 -40px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  
} */

#loader {
  position:fixed;
  width:100%;
  height:100%;
  background-color: rgba(255,255,255,0.7);
  z-index:9998;
}

@-webkit-keyframes spin {
from {-webkit-transform:rotate(0deg);}
to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
from {transform:rotate(0deg);}
to {transform:rotate(360deg);}
}

#loader::after {
  content:'';
  display:block;
  position:absolute;
  left:calc(50% - 20px);
  top:50%;
  width:40px;height:40px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;  
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Tooltip container */
.tooltip1 {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip1 .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  max-width: 200px;
  word-break: break-all;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.p-datepicker{
  z-index: 100000 !important;
}

.p-datepicker table td{
  padding: 0.2rem !important;  
}

.p-datepicker table td > span{
  font-size: 12px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.p-datepicker .p-timepicker span{
  font-size: 12px!important;
}

.p-datepicker table th > span {
  font-size: 12px !important;
}
.p-datepicker .p-timepicker{
  padding: 0 !important;
}

.headingFont{
  font-weight: 600 !important;
  font-size: 15px !important;
}

#q11 {
  display: block;
  background-color: rgba(136, 136, 136, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  display: flex;
  align-items: center;
}

.obj {
  content:'';
  display:block;
  left:50%;top:50%;
  width:40px;height:40px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;  
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.f-12{
  font-size: 12px!important;
}

@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 70px;
  }
  100% {
    height: 0;
  }
}

.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background-color: #316a9e !important;
  border-color: #316a9e !important;
}

.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
  background-color: #316a9e !important;
  border-color: #316a9e !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0.2rem #a6d5fa !important;
}
.p-datepicker table td > span.p-highlight {
  color: #316a9e !important; 
  background: #EEF2FF;
}

.err-text{
  color: red;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.buttonCss{
  color: #fff !important;
  background: #316a9e !important;
  border: 1px solid #316a9e !important;
  padding: 0.3rem 0.7rem !important;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s !important;
  border-radius: 3px !important;
  font-size: 12px !important;
  cursor: pointer !important;
}
.buttonCss:hover{
  color: #fff;
  background: #316a9e;
  border: 1px solid #316a9e;
  padding: 0.3rem 0.7rem;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
.buttonCss:disabled{
  color: #fff !important;
  background: #80a4c3 !important;
  border: 1px solid #80a4c3 !important;
  padding: 0.3rem 0.7rem;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  border-radius: 3px;
  font-size: 12px;
}
.p-invalid>.form-check-input {
  border-color: #f44336 !important;
}
.p-invalidBTN {
  border-color: #f44336 !important;
}
.p-invalidBTN .p-inputtext {
  border: 1px solid #f44336 !important;
}
.p-multiselect {
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  border-radius: 3px;
}
.p-multiselect .p-multiselect-label {
  padding: 0.3rem !important;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.3rem 0 !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.3rem 0.7rem !important;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.3rem 0.7rem !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #316a9e !important;
  background: #a6d5fa54 !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #316a9e !important;
  background: #316a9e !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem #a6d5fa !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #316a9e !important;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #316a9e !important;
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #a6d5fa !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  color: #316a9e !important;
  background: #a6d5fa54 !important;
  padding: 0 0.3rem !important;
  border-radius: 3px !important;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 !important;
  background: #fff !important;
  color: #495057 !important;
  padding: 1rem !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600 !important;
  font-size: 15px !important;
}
.p-dialog .p-dialog-content {
  background: #ffffff !important;
  color: #495057 !important;
  padding: 0 1rem 1rem !important;
}
.p-dialog .p-dialog-footer {
  padding: 10px !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus{
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #a6d5fa !important;
}
.p-dropdown {
  background: #fff !important;
  border: 1px solid #ced4da !important;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s !important;
  border-radius: 3px !important;
}
.p-fluid .p-dropdown .p-dropdown-label {
  width: 1% !important;
}
.p-dropdown .p-dropdown-label {
  background: #0000 !important;
  border: 0 !important;
}
.p-inputtext {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
  font-size: 0.75rem!important;
  color: #495057 !important;
  background: #fff !important;
  padding: 0.3rem !important;
  border: 1px solid #ced4da !important;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 3px !important;
}
.p-dropdown-panel{
  z-index: 1900 !important ;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #316a9e !important;
}
.success{
  color: #00a642;
  font-size: 12px;
    font-weight: 400;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c !important;
}
.p-dropdown.p-invalid.p-component  {
  border-color: #e24c4c !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #a6d5fa !important;
  border-color: #316a9e !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #a6d5fa !important;
  border-color: #316a9e !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057 !important;
  background: #e3f2fd !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0 !important;
  padding: 0.3rem 0.7rem !important;
  border: 0 !important;
  color: #495057 !important;
  background: #0000 !important;
  transition: box-shadow .2s !important;
  border-radius: 0 !important;
}
.p-dropdown-panel .p-dropdown-items.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper, .p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
  padding: 0.3rem 0 !important;
}

.dropdown-item{
  padding: 2px 10px 1px 10px !important;
  width: 100% !important;
}
.dropdown{
  text-align: center !important;
}
.p-component {
  font-family: Open Sans,Helvetica,Arial,sans-serif !important; 
  font-size: 12px !important;
  font-weight: 400 !important;
}
.dropdown-item:hover{
  background-color: #fff !important;
}
.dropdown-item::after{
  background-color: #fff !important;
}
.dropdown-toggle::after {
  display: none !important; 
}
.p-link, .pi {
  font-size: 12px !important;
}
input[type=radio] {
  width: 20px;
  height: 20px;
  margin-top: 0rem;
  border: 2px solid #ced4da;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
}
.modal-header {
  border: none !important;
}
.modal.show .modal-dialog {
  transform: none;
  display: flex;
  align-items: center;
}
button.btn-close {
  font-size: 10px;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #EEF2FF;
  color: gray !important;
}
.p-column-filter-menu-button:focus {
  box-shadow: 0 0 0 0.2rem #a6d5fa !important;
}
.calender-custom input[type="text"] {
  height: 30px;
  font-size: 12px;
}
.p-button.p-button-icon-only {
  width: 30px !important;
  height: 30px !important;
  padding: 0.75rem 0 !important;
}
.buttonIcon{
  padding: 0.3rem 0.3rem !important;
}
.downloadIcon{
  padding: 0.45rem 0.3rem 0.48rem 0.3rem !important;
}
.p-dropdown-items-wrapper{
  max-width: 615px !important;
 }
 .p-dropdown-item{
  white-space: normal !important;
 }
 /* .Toastify__progress-bar--success {
    background: #00a642 !important;
 } */
 .Toastify{
  font-size: 12px !important;
  z-index: 9999;
 }
 .rotate{
  animation: spin 1.5s infinite linear;
 }