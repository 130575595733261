.modal-dialog.deletemodal-200w.modal-lg.modal-dialog-scrollable {
  max-width: 25% !important;
}
.backdrop2{
  z-index: 1055;
}

@media screen and (min-width : 320px) and (max-width : 768px){
  .modal-dialog.deletemodal-200w.modal-lg.modal-dialog-scrollable {
    max-width: 100% !important;
    padding-left: 12%;
    padding-right: 12%;
  }
}