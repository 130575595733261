.modal-dialog.modal-200w.modal-sm.modal-dialog-centered.modal-dialog-scrollable {
  max-width: 800px;
}

.titleName {
  text-align: Left;
  font-size: 18px;
  color: rgb(79, 114, 157);
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

.titleText {
  font-size: 12px;
  font-weight: 600;
}

.calenderClass1 {
  color: black;
  background-color: #fff;
  width: 100%;
  height: 35px;
  border-radius: 3px;
}

.react-calendar button {
  height: 2.4rem;
}

.detailCol {
  color: #495057;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.popupClass {
  color: #495057;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.rec {
  margin-top: -12.5px;
}

@media screen and (max-width:769px) {
  .reclabel{
    margin-top: -9px;
  }
  .subreclabel{
    margin-top: -5px;
  }
}