.modal-dialog.reflectInfo-200w.modal-lg.modal-dialog-scrollable {
    max-width: 20% !important;
  }
  .msgClass{
    font-size: 12px;
    color: #495057;
  }


  /* @media screen and (max-width:769px) {
     {display:block;margin:0 auto;}
} */