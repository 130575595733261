.tableBackground{
  background: #fff;
  color: #495057;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
  border-radius: 10px;
  padding: 1.5rem 0.7rem;
}

.calenderClass{
  color: black;
  background-color: #fff;
  width: 100%;
  height: 50%;
  border-radius: 3px;
}
button.react-date-picker__calendar-button.react-date-picker__button {
  background-color: #316a9e;
  border-radius: 0px 3px 3px 0px;
}
button.react-date-picker__calendar-button.react-date-picker__button:hover{
  background-color: #669acd;
  border-color: #669acd;
  border-radius: 0px 3px 3px 0px;

}
.react-date-picker__wrapper{
  border: thin solid #ced4da;
  border-radius: 3px;
}
.react-date-picker__wrapper:hover{
  border: thin solid #316a9e;
  border-radius: 3px;
}
.react-date-picker__inputGroup{
  font-size: 12px;
  font-weight: 100;
  padding: 3px 2px 2px 5px;
}
.fontSize{
  font-size: 12px;
  font-weight: bold;
}
.pr-2{
  padding-right: 0.5rem;
}
.paddingBottom{
  padding: 0px 0px 10px 0px !important;
}
.icon{
  color: #fff;
  font-size: 12px;
}
.dateButton{
  color: #fff;
  background: #316a9e;
  border: 1px solid #316a9e;
  padding: 5px 10px 5px 10px;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  border-radius: 3px;
  font-size: 12px;
}

.filter-button{
  min-width: 65px;
}

.create-event-button{
  min-width: 148px;
}

.date-picker-table{
  min-width: 150px !important;
}


.dateButtonDiv{
  padding: 22px 10px 0px 10px;
}
.text-right{
  text-align: right;
}
.margin-top-20{
  margin-top: 20px;
}
.dropdown-item:hover{
  background-color: #fff !important;
}
.dropdown-item::after{
  background-color: #fff !important;
}
.dropdown-toggle::after {
  display: none !important; 
}
.MuiToolbar-root {
  justify-content: center !important;
}

.MuiTablePagination-spacer {
  flex: 0 !important;
}
.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  flex-direction: row-reverse;
}

.popover{
  max-width: 200px;
  z-index: 1001 !important;
  transform-origin: center top;
  /* transform: translate3d(64px, 520.5px, 0px) !important; */
  opacity: 1;
  left: 4% !important;
  border: 1px solid #999;
}
.popover-arrow{
  transform: translate3d(7.5px, 0px, 0px) !important;
}
.popover-body {
  padding: 0.7rem;
  color: #495057;
}
.fade {
  transition:none !important;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before, .bs-popover-bottom>.popover-arrow::before {
  border-bottom-color: #999;
}
.buttonClass{
  word-wrap: break-word;
  white-space: normal;
  width: 100%;
}
.dropdown-item{
  padding: 2px 10px 1px 10px;
  width: 100%;
}
.dropdown{
  text-align: center;
}
.MuiSelect-select.MuiSelect-select{
  border: 2px solid #e9ecef;
    border-radius: 3px;
}

.table-button-group{
  flex-wrap: wrap !important;

}


.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 0.5rem;
  border: 1px solid #bbdceb;
  font-weight: 600;
  color: #495057;
  background: #e6f2f8;
  transition: box-shadow .2s;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td, .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td, .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  border-width: 1px;
}

.p-datatable .p-column-header-content {
  white-space: nowrap;
}

.p-component {
  font-family: Open Sans,Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.p-datatable .p-datatable-tbody>tr {
  background: #fff;
  color: #495057;
  transition: box-shadow .2s;
  outline-color: #a6d5fa;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #bbdceb;
  padding: 1rem 0.5rem !important;
}
.p-datatable .p-datatable-thead>tr>th {
  position: relative;
}
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(2n) {
  background-color: #efefef;
}

.p-datatable .p-datatable-thead>tr>th:hover {
  background: #e9ecef;
  color: #495057;
  cursor: pointer;
}

.p-paginator {
  background: #fff;
  color: #6c757d;
  border: 0 solid #e9ecef;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.p-link, .pi {
  font-size: 12px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px;
}

.p-datatable .p-paginator-bottom, .p-datatable .p-paginator-top {
  border-width: 0 0 1px;
  border-radius: 0;
}

.p-paginator .p-paginator-current, .p-paginator .p-paginator-pages .p-paginator-page {
  background-color: initial;
  border: 0;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e3f2fd;
  border-color: #e3f2fd;
  color: #495057;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #316a9e;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #316a9e;
} 

.p-inputtext:enabled:hover {
  border-color: #316a9e!important;
}

.p-button.p-button-outlined {
  background-color: initial!important;
  color: #316a9e!important;
  border: 1px solid!important;
}
.p-button.p-component.p-button-outlined.p-button-sm {
  padding: 0.3rem 0.7rem!important;
  background: #fff!important;
  color: #316a9e!important;
}
.p-button.p-component.p-button-sm {
  color: #fff!important;
  background: #316a9e!important;
  border: 1px solid #316a9e!important;
  padding: 0.3rem 0.7rem!important;
  font-size: 12px!important;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s!important;
  border-radius: 3px!important;
  font-weight: 400!important;
}
.p-button-label {
  transition: all .2s!important;
  font-weight: 400!important;
}
.p-button.p-component.p-button-outlined.p-button-sm:hover {
  background: #2196f30a!important;
  color: #316a9e!important;
  border: 1px solid!important;
}
.p-button.p-component.p-button-sm:enabled:hover {
  background: #669acd!important;
  color: #fff!important;
  border-color: #669acd!important;
}


.p-column-filter-overlay {
  background: #fff;
  color: #495057;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px 0 #00000024, 0 1px 10px 0 #0000001f;
  min-width: 12.5rem;
}
.p-px-3 {
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}
.p-pb-0 {
  padding-bottom: 0!important;
}
.p-pt-3 {
  padding-top: 0.5rem!important;
}
.p-text-bold {
  font-weight: 700!important;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0;
  text-align: center;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 0.5rem 0rem;
    border-bottom: 1px solid #dee2e6;
}
/* .p-fluid .p-inputtext {
  width: 100%;
} */
.p-inputtext {
  margin: 0;
}
input.p-inputtext.p-component.p-element {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 1rem;
  color: #495057;
  background: #fff;
  padding: 0.2rem;
  border: 1px solid #ced4da;
  transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 0.7rem;
}
.p-column-filter-buttonbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-column-filter-buttonbar .p-button {
  width: auto;
}
.p-fluid .p-inputtext {
  width: 85%;
}
.p-left{
  text-align: left;
}
span.p-sortable-column-icon.pi.pi-fw.pi-sort-alt{
  display: none;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #316a9e;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #316a9e;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #e9ecef;
  color: #316a9e;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
  outline: 0 none;
}
span.p-sortable-column-icon.pi.pi-fw.pi-sort-amount-up-alt{
  display: none;
}
span.p-sortable-column-icon.pi.pi-fw.pi-sort-amount-down{
  display: none;
}
.p-paginator .p-paginator-current, .p-paginator .p-paginator-pages .p-paginator-page {
  background-color: initial;
  border: 0;
  color: #6c757d;
  min-width: 2.357rem !important;
  height: 2.357rem;
  margin: 0.143rem;
}
.p-paginator .p-paginator-current {
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-last, .p-paginator .p-paginator-next, .p-paginator .p-paginator-prev {
  background-color: initial;
  border: 0;
  color: #6c757d;
  min-width: 2.357rem !important;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow .2s;
  border-radius: 3px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px;
}
.p-datatable-emptymessage>td {
  text-align: center !important;
}
button.react-date-picker__calendar-button.react-date-picker__button:disabled {
  background-color: #669acd;
  border-color: #669acd;
  border-radius: 0px 3px 3px 0px;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: -15px;
}
.calendertextbox{
  padding-left: 8%;
  padding-right: 7%;
 } 

@media screen and (min-width: 437px) {
  .filter-button{
    margin-top: 19px;
  } 
  
}

.pi-chevron-down:before {
  margin-left: -0.25rem;
}

/* .p-component-overlay{
  background-color: white;
} */
.p-datatable-loading-overlay{
  background-color:rgba(136, 136, 136, 0.1);;
}