.fixed-top {
  position: inherit !important;
}

.header {
  display: flex;
  background-color: #fff;
}

.header .header-start {
  align-items: normal;
  padding: 5px 0 10px 0px;
  background-color: #fff;
}

.logoClass {
  width: 100%;
  max-width: 330px;
  min-width: 200px;
  height: auto;
}

.pt-1 {
  padding-top: 0.25rem!important;
  margin-left: -15px;
  margin-bottom: -4.5px;
}

.header-margin{
  margin: -15px; 
}

.user {
  display: inline-flex;
  padding: 6px 20px 0px 20px !important;
  cursor: pointer;
  color: #316a9e;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

/* .user:hover {
  background-color: #b0d5f6;
} */

.user_icon {
  margin-top: -5px;
  height: 27px;
  width: 27px;
  text-align: center;
  margin-left: 3px;
  color: #fff;
  background-color: #316a9e;
  border-radius: 50%;
}

.text-right {
  text-align: right;
}

.header-end {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.navbar {
  background-color: #316a9e;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}

.navlist {
  color: #fff !important;
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
}

.navlist:hover {
  background-color: #e9ecef !important;
  color: #495057 !important;
  border-radius: 5px;
}

.navlist:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}

.imageClass1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.navbar-toggler,
.navbar-toggler:focus-visible {
  border: none !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(307deg) brightness(103%) contrast(101%);
}

.navbar-toggler {
  color: #316a9e !important;
}

.navbar-nav .nav-link {
  padding-left: 6px !important;
}

.navbar-collapse {
  background-color: #316a9e;
  z-index: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.navbar-nav {
  padding-left: 10px !important;
}

@media screen and (min-width: 1919px ){
  .logoClass{
    max-width: 427px;
  }
}

@media (max-width: 768px ){
  .pt-1{
    margin-left: -17px;
    margin-top: -0.75px;
  }
  .navbar{
    margin-top: -5px;
  }
}