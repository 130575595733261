.table-button-group{
    flex-wrap: wrap;
}
.p-overlaypanel .p-overlaypanel-content {
    padding: 0.7rem;
}
.event-overlay {
    max-width: 200px;
    border: 1px solid #999;
}
.p-overlaypanel:after, .p-overlaypanel:before {
    border: solid;
    border-color: #fff0 #fff0 #fff;
}
.p-overlaypanel:before {
    border-bottom-color: #999;
}
.p-overlaypanel:before {
    border-width: 10px;
    margin-left: -10px;
}
.mainpagetablecontent{
    padding-bottom: 0.7rem;
}
.p-dialog-center{
    z-index: 999 !important
}
/* .pointer{
    cursor: default !important;
} */