.chooseIcon{
  font-size: 20px;
}
.imageDiv{
  flex: 1 1 auto;
  width: 25%;
}
.imageDiv1{
  flex: 1 1 auto;
  width: 25%;
  text-align: right;
  padding: 3px;
}
.imageText{
  word-break: break-all;
  padding: 10px;
  font-weight: 400;
}
.MuiToolbar-root {
  justify-content: center !important;
}

.MuiTablePagination-spacer {
  flex: 0 !important;
}
.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  flex-direction: row-reverse;
}
.toolTip
{
  max-width: 100%;
}
 .downloadclass{ padding: 6.800000000000001px 12.5px 6.5px 11px !important }
/*.deleteclass{ padding: 3.8px 5.5px 3.5px 5px !important } */